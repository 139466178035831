<template>
  <div :class="open ? 'faq open' : 'faq'" @click="$emit('toggleOpen', index)">
    <div class="question">{{ faq.question }}</div>
    <div class="wrap">
      <div class="answer">{{ faq.answer }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  props: {
    faq: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.faq{
  color: #000;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;

  &.open{
    .wrap {
      max-height: 1000px;
      opacity: 1;
    }

    .question{
      &::after{
        transform: rotateX(180deg);
      }
    }
  }

  .wrap{
    max-height: 0;
    overflow: hidden;
    transition: all 0.3s ease-out;
    opacity: 0;
  }

  .question{
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;

    &::before{
      content: '';
      width: 5px;
      height: 5px;
      background: #4BB222;
      display: inline-block;
      border-radius: 50%;
      margin-right: 10px;
    }

    &::after{
      content: '';
      display: inline-block;
      background: url("../../../assets/icons/bottom-arrow.png") center no-repeat;
      height: 12px;
      width: 12px;
      margin-left: 6px;
      transform: rotateX(0);
      transition: all .5s ease-in-out;
    }
  }

  .answer{
    padding-top: 15px;
    font-size: 12px;
  }
}
</style>
