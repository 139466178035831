<template>
  <div class="support-page">
    <top-bar />
    <page-title title="Помощь" description="Часто задаваемые вопросы" />
    <div class="container">
      <div class="faqs">
        <n-loader :loading="$var('load')" />
        <faq v-for="(faq, i) in faqs"
             :key="i"
             :faq="faq"
             :index="i"
             :open="faq.open"
             @toggleOpen="toggleOpen"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Faq from './components/Faq'

export default {
  name: 'Index',
  components: { Faq, },
  data() {
    return {
      faqs: [],
    }
  },
  created() {
    this.load()
  },
  methods: {
    toggleOpen: function(index) {
      this.faqs = this.faqs.map((faq, i) => {
        if (index === i) {
          faq.open = !faq.open
        }
        return faq
      })
    },
    load() {
      this.$var('load', true)
      $api.app.faqs(this.id, this.name).then((response) =>{
        this.faqs = response.data.content
      }).finally(() => {
        this.$var('load', false)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.support-page {
  .faqs{
    position: relative;
  }
}
</style>
